<template>
	<v-card class="my-2 mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" max-width="800" style="width: 100%" rounded="xl">
		<v-card-title class="d-flex flex-nowrap justify-space-between align-start flex-grow-1">
			<span class="font-weight-bold" style="word-break: all">{{ course.name }}</span>

			<v-dialog v-model="dialog" persistent max-width="350">
				<template v-slot:activator="{ on }">
					<v-btn icon color="error" v-on="on">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-card-title>
						<span class="headline">{{ $t('courses.areYouSure') }}</span>
					</v-card-title>
					<v-card-text> You will no longer have access to this course nor its folder </v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn color="blue darken-1" text rounded @click="dialog = false"> No </v-btn>
						<v-btn color="error darken-1" text rounded @click="callLeaveCourse()">
							{{ $t('courses.leave') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-card-title>
		<v-card-subtitle class="flex-grow-1">
			{{ course.shortDescription }}
		</v-card-subtitle>
		<v-card-text class="pb-0">
			<v-chip-group column class="my-2">
				<v-chip color="info darken-1">
					<v-btn
						text
						x-small
						:to="{ name: 'CourseFeedPosts', params: { id: course.id } }"
						block
						rounded
						plain
						class="px-0 white--text text--darken-1"
					>
						<v-icon class="mr-3"> mdi-rss </v-icon>
						{{ $t('nav.newsfeed') }}
					</v-btn>
				</v-chip>
				<v-chip color="info darken-1">
					<v-btn text x-small :to="{ name: 'CourseDetails', params: { id: course.id } }" block rounded plain class="px-0 white--text text--darken-1">
						<v-icon class="mr-3"> mdi-details </v-icon>
						{{ $t('courses.details') }}
					</v-btn>
				</v-chip>
				<v-chip color="warning darken-1" v-if="course.isGDriveSetup">
					<v-btn text x-small :to="{ name: 'CourseExplorer', params: { id: course.id } }" block rounded plain class="px-0 white--text text--darken-1">
						<v-icon class="mr-3"> mdi-folder </v-icon>
						{{ $t('courses.explorer') }}
					</v-btn>
				</v-chip>
			</v-chip-group>
			<div class="d-flex justify-center flex-wrap align-center">
				<v-menu :open-on-hover="false" :close-on-click="true" :close-on-content-click="true" :offset-x="false" :offset-y="true">
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on" :loading="loadingSharing">
							<v-icon>mdi-google-drive</v-icon>
						</v-btn>
					</template>
					<v-card elevation="20" rounded="xl">
						<v-list color="transparent" dense nav rounded class="pa-0">
							<v-list-item
								v-for="(gDriveEmail, index) in gDriveEmails"
								:key="index"
								:class="{
									active: course.sharedGDriveEmail == gDriveEmail.email,
								}"
								@click="shareFolder(gDriveEmail)"
								class="px-4"
							>
								<v-list-item-content>{{ gDriveEmail.email }}</v-list-item-content>
								<v-list-item-icon v-if="course.sharedGDriveEmail == gDriveEmail.email">
									<v-icon color="green"> mdi-check </v-icon>
								</v-list-item-icon>
							</v-list-item>
						</v-list>
						<v-list-item dense class="red px-4" @click="unshareFolder()" v-if="course.sharedGDriveEmail">
							<v-list-item-content>{{ $t('courses.unlinkFolder') }}</v-list-item-content>
						</v-list-item>
					</v-card>
				</v-menu>

				<div class="ml-3">
					<small v-if="course.sharedGDriveEmail" class="green--text">{{ course.sharedGDriveEmail }}</small>
					<small v-else>{{ $t('courses.noAccountLinked') }}</small>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'MyCourseCard',
	props: {
		course: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			dialog: false,
			loadingSharing: false,
		}
	},
	computed: {
		...mapGetters({
			gDriveEmails: 'gdriveemails/gDriveEmails',
		}),
	},
	methods: {
		callLeaveCourse() {
			this.leaveCourse(this.course.id)
			this.dialog = false
		},
		shareFolder(gDriveEmail) {
			this.loadingSharing = true
			this.share({ course: this.course, gDriveEmail: gDriveEmail.email })
				.then(({ success }) => {
					if (success) {
						this.course.sharedGDriveEmail = gDriveEmail.email
					}
				})
				.then(() => {
					this.loadingSharing = false
				})
		},
		unshareFolder() {
			this.loadingSharing = true
			this.unshare(this.course)
				.then(({ success }) => {
					if (success) {
						this.course.sharedGDriveEmail = ''
					}
				})
				.then(() => {
					this.loadingSharing = false
				})
		},
		...mapActions('courses', ['leaveCourse', 'share', 'unshare']),
	},
}
</script>

<style scoped>
.v-menu__content {
	border-radius: 24px !important;
}
</style>
